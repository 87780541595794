@import "desktop_responsive.css";
@import "tablet_responsive.css";
@import "tablet_mini_responsive.css";
@import "mobile_responsive.css";

:root {
  --color-primary: #56c869;
  --color-secondary: #e5e559;
  --color-tertiary: #36e48d;
  --color-primary-darker: #55d37f;
  --color-secondary-darker: #d8d850;
  --color-tertiary-darker: #2ed17f;
  --color-primary-opacity: #5dd8e137;
  --color-secondary-opacity: #ffcd0331;
  --color-tertiary-opacity: #54e19a37;
  --gradient-primary: linear-gradient(to top left, rgb(37, 150, 63), #6df496);
  --gradient-secondary: linear-gradient(to top left, #ffb003, #ffcb03);
  --color-gold: rgb(187, 165, 61);
  --model-img-padding: 6rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.circle_img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #333;
}
